import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import Header from './globalHeader';
import Footer from './globalFooter';
import VigPixel from './vigPixel';
import HeroBanner from './heroBanner';
import ColumnSection from './columnSection';
import RowSection from './rowSection';
import Carousel from './carouselSlider';
import FormBlock from './formBlock';
import Testimonial from './testimonialBlock';
import SingleContent from './singleContent';
import OverlapBlock from './overlapBlock';
import QuadrantBlock from './quadrantBlock';
import ContactBanner from './contactBanner';
import MapBlock from './mapBlock';
import { fetchContent } from '../services/strapiService';
import { replacePlaceholders } from '../utils/replacePlaceholders';
import { updateFaviconAndTitle } from '../utils/metaAssets';

function DynamicPage() {
  const { key } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const adGroup = searchParams.get('adgroup') || 'default';
  const isB2B = location.pathname.includes('/clients');

  const [contentData, setContentData] = useState(null);
  const [error, setError] = useState(null);
  const [variables, setVariables] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [headerImage, setHeaderImage] = useState(null);
  const [ctaText, setCtaText] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [websiteTerms, setWebsiteTerms] = useState(null);
  const formRef = useRef(null);

  const componentMap = {
    heroBanner: HeroBanner,
    columnSection: ColumnSection,
    rowSection: RowSection,
    carousel: Carousel,
    formBlock: FormBlock,
    testimonial: Testimonial,
    singleContent: SingleContent,
    overlapBlock: OverlapBlock,
    quadrantBlock: QuadrantBlock,
    contactBanner: ContactBanner,
    mapBlock: MapBlock,
  };

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const getContent = async () => {
      const domain = window.location.hostname; // Extract the domain here

      try {
        const response = await fetch(`https://lp-api-506685445850.us-central1.run.app/get-content/${domain}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch content: ${response.statusText}`);
        }

        const { metadata, components, colors, variables: variablesData, form } = await response.json();

        const locationVariables = variablesData[key];
        if (!locationVariables) {
          setError(`No content found for key: ${key}`);
          return;
        }

        setVariables(locationVariables);
        setHeaderImage(metadata.headerImage);
        setCtaText(metadata.ctaText);
        setPrivacyPolicy(metadata.privacyPolicy);
        setWebsiteTerms(metadata.websiteTerms);

        const root = document.documentElement;
        Object.entries(colors).forEach(([key, value]) => {
          root.style.setProperty(key, value);
        });

        const campaignType = isB2B ? 'clients' : 'default';
        const selectedFormFields =
          form?.[campaignType]?.length > 0
            ? form[campaignType]
            : [
                { name: 'firstName', label: 'First name', type: 'text', required: true },
                { name: 'lastName', label: 'Last name', type: 'text', required: true },
                { name: 'email', label: 'Email', type: 'email', required: true },
                { name: 'phone', label: 'Phone', type: 'tel', required: true },
              ];

        setFormFields(selectedFormFields);

        const campaignComponents =
          components[campaignType]?.[adGroup] ||
          components[campaignType]?.default ||
          [];

        const componentsData = await Promise.all(
          campaignComponents.map(async (component) => {
            let content = await fetchContent(component.contentType, component.id);
            content = replacePlaceholders(content.data, locationVariables);
            return { type: component.type, data: content };
          })
        );

        setContentData({ components: componentsData });
        updateFaviconAndTitle(metadata.favicon || null, metadata.metaTitle || null);
      } catch (err) {
        console.error('Error fetching content:', err);
        setError(err.message);
      }
    };

    getContent();
  }, [key, isB2B, adGroup]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!contentData) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'black',
        }}
      >
      </div>
    );
  }

  const domain = window.location.hostname; // Ensure domain is accessible here

  return (
    <div>
      {variables?.scriptParams && (
        <VigPixel
          iParam={variables.scriptParams.i}
          sParam={variables.scriptParams.s}
        />
      )}
      <Header headerImage={headerImage} address={variables?.address} phone={variables?.phone} city={variables?.city} state={variables?.state}/>
      {contentData.components.map((component, index) => {
        const ComponentToRender = componentMap[component.type];
        if (!ComponentToRender) {
          console.warn(`No component found for type: ${component.type}`);
          return null;
        }

        const props = {
          data: component.data,
          ...(component.type === 'formBlock' && {
            ref: formRef,
            urlDomain: domain,
            urlKey: key,
            variables: variables,
            formFields,
          }),
          ...(component.type === 'heroBanner' && {
            scrollToForm,
            ctaText,
            variables: variables,
          }),
          ...(component.type === 'contactBanner' && {
            variables: variables,
            scrollToForm,
          }),
          ...(component.type === 'mapBlock' && {
            variables: variables,
            scrollToForm,
          }),
        };

        return (
          <div className={`page-container ${component.type}-container`} key={index}>
            <ComponentToRender {...props} />
          </div>
        );
      })}
      <Footer
        privacyPolicy={privacyPolicy}
        websiteTerms={websiteTerms}
      />
    </div>
  );
}

export default DynamicPage;